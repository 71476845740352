import AdminMenuBarPage from '../components/sidebar';
import AdminCategoriePage from '../categories';
import ProductViewPage from '../product';
import AdminUserPage from '../user';

const AdminDashboardPage = ({ component }) => {




    return (
        <>
            {
                component == 'categories' ? (<AdminCategoriePage></AdminCategoriePage>)
                    : component == 'product' ? (<ProductViewPage></ProductViewPage>)
                        : component == 'users' ? (<AdminUserPage></AdminUserPage>) : "hlo"
            }


        </>
    )
}
export default AdminDashboardPage;