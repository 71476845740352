import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserAuthTokenAPI } from './userApi';

const initialState = {
  loginStatus: 'idle',
  userDetails: null,
  userDetail:null
};

export const getUserAuthTokenAsync = createAsyncThunk(
  'user/getUserAuthToken',
  async (userCredentials) => {
    const response = await getUserAuthTokenAPI(userCredentials);
    return response.data;
  }
);
// export const getUserDetailsAsync = createAsyncThunk(
//   'user/getUserDetailsAsync',
//   async (userCredentials) => {
//     const response = await getUserDetailsAPI(userCredentials);
//     return response.data;
//   }
// );

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem('token',( action.payload.response.data.data));
      localStorage.setItem('refreshToken',( action.payload.response.data.data));
    },
  },



  extraReducers: (builder) => {
    builder
      // getUserDetails thunk states
      .addCase(getUserAuthTokenAsync.pending, (state) => {
        state.loginStatus = 'pending';
      })
      .addCase(getUserAuthTokenAsync.fulfilled, (state, action) => {
        console.log(action.payload.data.response)
        state.loginStatus = 'success';
        state.userDetail = action.payload.data;
      })
      .addCase(getUserAuthTokenAsync.rejected, (state, action) => {
        state.loginStatus = 'failed';
        console.log(action.error);
      })

  }

})

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions

export default userSlice.reducer


export const selectShopWithInvoice = (state) => {
  return state.shop.selectedRoute.shops.filter(shop => shop.processing.length);
}
//getting token 
export const getAccessToken=(state)=>{
  return state.user?.userDetails?.response?.data?.data;
}