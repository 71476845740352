import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

// material-ui
import {
  Grid,
  Stack,
  Typography,
  InputLabel,
  FormHelperText,
  useTheme
} from "@mui/material"

// ant-ui
import { Input, Button } from "antd"

// third party
import * as Yup from 'yup'
import { Formik } from "formik"

// project import
// import { getUserAuthToken } from "api/"
import { getUserAuthToken,instance } from "../../api/authentication"

// import { getCurrentUser } from "../../api/common"

import { setUser } from "../../store/user/userSlice"


// ============================== LOGIN - FORM ==============================

const LoginForm = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      .max(255, 'Username must be atmost 255 characters')
      .required('Username is required'),
    password: Yup.string()
      .max(255, 'Password must be atmost 255 characters')
      .required('Password is required')
  })

  return (
    <Formik
      initialValues={{
        userName: '',
        password: ''
      }}
      // validationSchema={formValidationSchema}
      onSubmit={async (values, actions) => {
        try {
          const response = await getUserAuthToken(values)

         
        

          // Store the tokens in localStorage or secure cookie for later use
          // localStorage.setItem('token', token);
          // localStorage.setItem('refreshToken', refreshToken);

          if (response.data.message == "Success") {
           
            

            // Store the tokens in localStorage or secure cookie for later use
           
            dispatch(setUser({
              response
            }))

            navigate('/dashboard', { replace: true })
          } else {
            actions.setErrors({ submit: 'User not authorized' })
          }
        } catch (err) {
          actions.setErrors({ submit: 'Invalid username or password' })
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ padding: `${theme.spacing(4)} ${theme.spacing(3)}` }}
        >
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} mb={1}>
              <Stack spacing={1}>
                <Typography variant="h3"></Typography>
                <Typography variant="h6" color='grey'></Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <InputLabel>Username</InputLabel>
                <Input
                  id="userName"
                  name="userName"
                  value={values.userName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  status={(touched.userName && errors.userName) || errors.submit ? "error" : null}
                />
                {touched.userName && errors.userName && (
                  <FormHelperText error>
                    {errors.userName}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <InputLabel>Password</InputLabel>
                <Input.Password
                  id="password"
                  name="password"
                  autoComplete="off"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  status={(touched.password && errors.password) || errors.submit ? "error" : null}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>
                    {errors.password}
                  </FormHelperText>
                )}
                {errors.submit && (
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} mt={1}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik >
  )
}

export default LoginForm
