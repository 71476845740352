import React, { useCallback, useMemo, useState ,useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MuiFileInput } from 'mui-file-input'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import instance from '../../../api/authentication';


import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, }) => {

    const[productItem,setProductItem]=useState([]);
    const [file, setFile] = React.useState([]);
    const [formatData, setFormatData]=useState({});
    const [opens, setOpen] = React.useState(false);
    const[categoryItem,setCategoryItem]=useState([])
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
    const handleChangeFile = (newFile) => {
      setFile(newFile) 
    }

    
    //Api call for category listing
  const getCategoryList =()=>{
    instance
    .get(`/getAllCategories`)
    .then((response)=>{
      if(response.data.message == "Success"){
        setCategoryItem(response.data.data)
       
      }
    })
  }
  useEffect(()=>{
    getCategoryList();
  },[])
  
 
  
    //!-----=======Api call for image upload =========----!
  
  
  //!----========Api call for product submit =========----!
  
  
  const handleonSubmit =(productItem)=>{
  
  
    if(file){
          const formData = new FormData();
          formData.append('file', file);
        instance
          .post(`/upload`,formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
          .then((response)=>{
            const data = response.data;
            setFormatData(data.data);
            console.log(formatData)
            
          })
        
      }
      console.log(formatData)
    const postData={
      name:productItem.name,
      description:'hlo',
      price:productItem.price,
      category:productItem.category,
      images:formatData
    };
    instance
    .post(`/createProduct`, postData)
    .then((Response)=>{
      console.log(Response)
      onClose();
    })
  }
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Product</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setProductItem({ ...productItem, [e.target.name]: e.target.value })
                  }
                />
              ))}
              <FormControl sx={{ m: 1, minWidth: 140 }}>
          <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={opens}
            onClose={handleClose}
            onOpen={handleOpen}
            name='category'
            label="Category"
            onChange={(e) =>
              setProductItem({ ...productItem, [e.target.name]: e.target.value })
            }
          >
           
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categoryItem?.map((state)=>{
              return(
                <MenuItem key={state._id} value={state._id}>
              {state.categoryName}
            </MenuItem> 
              )
              
            })}
           
          </Select>
        </FormControl>
               <MuiFileInput   label="Attachment"  value={file}  onChange={handleChangeFile}/>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={()=>handleonSubmit(productItem)} variant="contained">
           Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };