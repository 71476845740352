import { combineReducers } from "@reduxjs/toolkit";

import userReducer from './user/userSlice';


import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
    user: userReducer
});

// To reset redux store and the local storage when a user clicks login:
// Read this https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        storage.removeItem('persist:chocolate-dough')
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
}