
import React, { useCallback, useMemo, useState ,useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MuiFileInput } from 'mui-file-input'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';
import instance from '../../../api/authentication';



import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export const EditNewProductAccount =({columns,editRow,open,onClose})=>{
    const[categotyItem,setCategoryItem]=useState([]);
    const[productItem,setProductItem]=useState([]);
    const [file, setFile] = React.useState([]);
    const [formatData, setFormatData]=useState({});
    const [opens, setOpen] = React.useState(false);
   
  console.log(editRow)
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
    const handleChangeFile = (newFile) => {
      setFile(newFile) 
    }

     //Api call for category listing
  const getCategoryList =()=>{
    instance
    .get(`/getAllCategories`)
    .then((response)=>{
      if(response.data.message == "Success"){
        setCategoryItem(response.data.data)
        //  setCreateModalOpen(false)
        //  setEditModalOpen(false)
      }
    })
  }
  useEffect(()=>{
    getCategoryList();
  },[])

const handleonSubmit=()=>{

}
  return (
    <>
     
    <Dialog open={open}>
        <DialogTitle textAlign="center">Edit Product</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setProductItem({ ...productItem, [e.target.name]: e.target.value })
                  }
                />
              ))}
              <FormControl sx={{ m: 1, minWidth: 140 }}>
          <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={opens}
            onClose={handleClose}
            onOpen={handleOpen}
            name='category'
            label="Category"
            onChange={(e) =>
              setProductItem({ ...productItem, [e.target.name]: e.target.value })
            }
          >
           
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categotyItem?.map((state)=>{
              return(
                <MenuItem key={state._id} value={state._id}>
              {state.category}
            </MenuItem> 
              )
              
            })}
           
          </Select>
        </FormControl>
               <MuiFileInput   label="Attachment"  value={file}  onChange={handleChangeFile}/>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={()=>handleonSubmit(productItem)} variant="contained">
           Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
    )
}