import { CreateNewAccountModal } from './newaccountModel';
import { EditNewAccountModal } from './editAccount';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import instance from '../../api/authentication';
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import './index.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});






const AdminCategoriePage= () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  //edit page opening
  const [editModalOpen, setEditModalOpen] = useState(false);
  const[editRow,setEditRow]=useState('');
  const[deleteRow,setDeleteRow]=useState('');
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row) => {
    setDeleteRow(row.original)
    setOpen(true);
  };


  
//delete api ------>category
  const handleClose = (deleteRow) => {
    console.log(deleteRow)
    const postData={
      _id:deleteRow._id,
  };
  instance
    .post(`/deleteCategory`,postData)
    .then((Response)=>{
      console.log(Response)
      getCategoryList();
      setOpen(false);
    })
   
  };



  //Api call for category listing
  const getCategoryList =()=>{
    instance
    .get(`/getAllCategories`)
    .then((response)=>{
      if(response.data.message == "Success"){
        setTableData(response.data.data)
         setCreateModalOpen(false)
         setEditModalOpen(false)
      }
    })
  }
  useEffect(()=>{
    getCategoryList();
  },[])

  const handleSaveRowEdits=(row)=>{
    console.log(row);
    setEditRow(row.original);
    setEditModalOpen(true)
  }
 

  

  

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
  
      {
        accessorKey: 'categoryName',
        header: 'Category',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 1500,
      },
      
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Category
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(deleteRow)}>Yes</Button>
          <Button onClick={()=> setOpen(false)}>No</Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable Style={{width:"100px"}}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 180,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
       
          <Box sx={{ display: 'flex', gap: '1rem' }}>
             
            <Tooltip arrow placement="left" title="Edit"  >
              <IconButton   onClick={() => handleSaveRowEdits(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleClickOpen(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Account
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={getCategoryList}
        
      />
      <EditNewAccountModal
      open={editModalOpen}
      editRow={editRow}
      onClose={getCategoryList}
      />
    </>
  );
};


const validateRequired = (categotyItem) => !categotyItem.length;
const validateEmail = (email) =>
  !email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;




export default AdminCategoriePage;