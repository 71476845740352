import { Navigate, Route, Routes } from 'react-router-dom';
// !--======component page======--!
import AdminLoginPage from './routes/login';
import AdminDashboardPage from './routes/dasboard';
import AdminMenuBarPage from './routes/components/sidebar';
import LoginForm from './routes/login/index';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/login' replace />} />
      <Route path='/login' element={<LoginForm />} />
      <Route path='*' element={<Navigate to='/' replace />} />
      <Route path='dashboard' element={<AdminMenuBarPage />} />
    </Routes>
  );
}

export default App;
