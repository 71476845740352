
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import instance from '../../../api/authentication';
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {

    const[categotyItem,setCategoryItem]=useState([]);
   
  
    
  
    // !---======Apicall for catagory submit======---!
  const handleonSubmit=(categotyItem)=>{
    console.log(categotyItem)
    const postData={
      categoryName:categotyItem.categoryName,
      description:""
    };
   instance
    .post(`/createCategory`,postData)
    .then((Response)=>{
      console.log(Response)
      onClose();
    })
  
  }
  
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Category</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
                <TextField
                  // key={}
                  label='CategoryName'
                  name='categoryName'
                  onChange={(e) =>
                    setCategoryItem({ ...categotyItem, [e.target.name]: e.target.value })
                  }
                />
            </Stack>
            </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={()=>handleonSubmit(categotyItem)}  variant="contained">
           Submit
          </Button>
        </DialogActions>
       
      </Dialog>
    );
  };
  