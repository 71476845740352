import axios from "axios"
import { useSelector } from "react-redux"
const { REACT_APP_BASE_API_URL } = process.env

// ============================== API - AUTHENTICATION ==============================

// Get authentication token from keycloak
export function getUserAuthToken(credentials) {
    const url = `${REACT_APP_BASE_API_URL}/login`

    const data = {
        ...credentials
    }

    return axios.post(url, data)
}



const instance = axios.create({
    baseURL: `${REACT_APP_BASE_API_URL}`,
  });
  
  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      console.log(token)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  export default instance