

import { CreateNewAccountModal } from './productAccountModel';

import { EditNewProductAccount } from './editAccountProduct';

import React, { useCallback, useMemo, useState ,useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MuiFileInput } from 'mui-file-input'
import {
  Box,
  Button,
 
  IconButton,
 
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import instance from '../../api/authentication';
 //delete msg poppup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
const ProductViewPage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const[deleteRow,setDeleteRow]=useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const[editRow,setEditRow]=useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row) => {
    setDeleteRow(row.original)
    setOpen(true);
  };

  
 //delete api ------>category
 const handleClose = (deleteRow) => {
  console.log(deleteRow)
  const postData={
    _id:deleteRow._id,
};
instance
  .post(`/deleteProductById`,postData)
  .then((Response)=>{
    console.log(Response)
    getProductList();
    setOpen(false);
  })
 
};





  //Api call for product listing
  const getProductList =()=>{
    instance
    .get(`/getAllProducts`)
    .then((response)=>{
      if(response.data.message == "Success"){
        setTableData(response.data.data);
        setCreateModalOpen(false)
      }
    })
  }
  useEffect(()=>{
    getProductList();
  },[])

  const handleSaveRowEdits=(row)=>{
    console.log(row);
    setEditRow(row.original
      );
    setEditModalOpen(true)
  }
 
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
    //   if (
    //     !confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
    //   ) {
    //     return;
    //   }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
     
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <>
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Category
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(deleteRow)}>Yes</Button>
          <Button onClick={()=> setOpen(false)}>No</Button>
        </DialogActions>
      </Dialog>

      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleSaveRowEdits(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleClickOpen(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Product
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={getProductList}
       
      />
      <EditNewProductAccount
       columns={columns}
       open={editModalOpen}
      editRow={editRow}
      onClose={getProductList}
      
      />
    </>
  );
};



const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    const validateAge = (age) => age >= 18 && age <= 50;

export default ProductViewPage;














